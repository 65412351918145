@import '../../../assets/style/variables/colors.scss';

.header.MuiAppBar-root {
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  &.open {
    margin-left: 15rem;
    width: calc(100% - 15rem);
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  .MuiToolbar-root {
    padding: 0 0.5rem;
  }

  a {
    text-decoration: none;
    color: white;

    .app-title {
      padding-left: 1rem;
    }
  }

  .placeholder {
    flex-grow: 1;
  }

  .language-menu-button {
    padding: 0.75rem;
    border-radius: 25%;

    .current-language-icon {
      margin-right: 0.5rem;
    }

    .current-language-abbr {
      color: $white;
    }
  }

  .profile-menu-button {
    .profile-picture {
      background: $white;
    }
  }
}

// It has to be outside of the header
.profile-menu {
  .profile-menu-header {
    padding: 0.5rem 1rem;

    .username {
      font-weight: bold;
    }
  }

  .profile-menu-item-divider {
    margin: 0.5rem 0;
  }
}

.MuiDrawer-root {
  .MuiToolbar-root {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .MuiDrawer-paper {
    position: relative;
    white-space: nowrap;
    width: 15rem;
    -webkit-transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    box-sizing: border-box;
  }

  &.closed .MuiDrawer-paper {
    overflow-x: hidden;
    -webkit-transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    width: 3.5rem;
  }

  .MuiListItemIcon-root {
    min-width: 2.5rem;
  }
}
