.metadata {
  .field {
    display: flex;
    align-items: center;

    &.notes {
      padding-top: 0.5rem;
      align-items: flex-start;
    }
  }

  .notes-area .MuiInputBase-root {
    padding: 0;
  }

  .MuiFormControl-root {
    margin: 0;

    input,
    textarea {
      padding: 0.5rem 0.75rem;
    }
  }
}
