.uploaded-file {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  background-color: #fafafa;
  border-radius: 0.5rem;
  gap: 0.5rem;


  .filename {
    flex: 1;
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .MuiSvgIcon-root {
    font-size: 1.25rem;;
  }

  .file-actions {
    display: flex;
  }
}
