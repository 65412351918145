@import '../../../assets/style/variables/colors.scss';

.inspection-view {
  .MuiCardHeader-root {
    background-color: $primary;
    color: $white;

    .MuiCardHeader-action .MuiSvgIcon-root {
      color: $white;
    }
  }

  .MuiPaper-root {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .metadata {
      display: grid;
      column-gap: 1.5rem;
      row-gap: 0.5rem;
      grid-auto-rows: max-content;
      grid-template-columns: max-content 1fr;

      .field {
        font-weight: 700;
      }
    }
  }

  .files-wrapper {
    .file-type {
      display: flex;
      align-items: center;
      font-weight: bold;
      gap: 0.25rem;

      .MuiSvgIcon-root {
        font-size: 1.25rem;
      }
    }

    .files {
      display: flex;
      padding: 0.75rem 0 1.5rem 1.5rem;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}
