@import '../../assets/style/variables/colors.scss';

#root {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content;
  grid-template-areas: 'header' 'main' 'footer';
  color: $content;

  .header {
    position: relative;
    grid-area: header;
  }

  main {
    grid-area: main;
    padding: 1rem;
    background-color: #f5f5f5;
    overflow-y: scroll;
  }

  footer {
    grid-area: footer;
  }
}
