.file-upload {
  padding: 0.5rem;
  background-color: #fafafa;
  border-radius: 0.5rem;

  &:not(.hasMedia) {
    background-color: #f0f0f0;

    &:hover {
      background-color: #eaeaea;
      cursor: pointer;
    }
  }

  .upload-placeholder {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    .MuiSvgIcon-root {
      font-size: 1.25rem;
    }
  }
}
